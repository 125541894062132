import { Container, Row, Image, Col } from "react-bootstrap";
import intentLogo from "../../img/network.jpg";

function NetworkSolutions() {
  return (
    <div
      style={{
        padding: "60px 0",
      }}
      className="pt-5 pb-5"
    >
      <Container
        style={{
          padding: "5% 0% 5% 0%",
        }}
      >
        <Row>
          <Col xs lg="7">
            <h2 className="mb-3" style={{ fontSize: "calc(1.5em + 1.5vw)" }}>
              Network Solutions
            </h2>
            <p className="mb-3" style={{ fontSize: "calc(0.5em + 0.5vw)" }}>
              We help organizations design, build, optimize, and secure their
              computer networks to ensure reliable and efficient communication
              and data transfer. Some common services provided by our company
              are: Network Design and Architecture, Network Installation and
              Configuration, Network Optimization and Performance, Network
              Troubleshooting and Support, Network Monitoring and Management,
              Network Security Solutions, Network Consulting and Advisory etc.
            </p>
            <div></div>
          </Col>
          <Col
            xs
            lg="5"
            className="d-flex justify-content-center align-items-center h-100 my-auto"
          >
            <Image
              src={intentLogo}
              width={"auto"}
              height={350}
              alt="logo"
              style={{
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NetworkSolutions;
