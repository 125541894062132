import React from "react";
import video1 from "../../video/homepagevideo.mp4";
import "./headerabout.css";
function HeaderAbout({ title }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        margin: 0,
        padding: 0,
        position: "relative",
      }}
    >
      <video src={video1} autoPlay loop muted id="video-homepage" />
      <div id="overlay" />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          minHeight: "100%",
          minWidth: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="text-white" style={{ textAlign: "center" }}>
          <h1 className="mb-5" style={{ fontSize: "calc(1em + 2vw)" }}>
            Intent Foreign Education and Project Consultancy
          </h1>
          <p className="mb-5" style={{ fontSize: "calc(0.5em + 0.5vw)" }}>
            If the UK is your land of dreams, we would be very delighted to help
            you on developing your career or growing your business through our
            great dedication, great experience and list of services that we
            offer.
          </p>
          <a
            className="btn btn-outline-light btn-lg"
            href="/about"
            role="button"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

export default HeaderAbout;
