import { Container, Row, Image, Col } from "react-bootstrap";
import intentLogo from "../../img/software.jpg";

function SoftwareDevelopment() {
  return (
    <div
      style={{
        backgroundColor: "#edf0f2",
      }}
    >
      <Container
        style={{
          padding: "5% 0% 5% 0%",
        }}
      >
        <Row>
          <Col
            xs
            lg="5"
            className="d-flex justify-content-center align-items-center h-100 my-auto"
          >
            <Image
              src={intentLogo}
              width={"auto"}
              height={350}
              alt="logo"
              style={{
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              }}
            />
          </Col>
          <Col xs lg="7">
            <h2 className="mb-3" style={{ fontSize: "calc(1.5em + 1.5vw)" }}>
              Software Development
            </h2>
            <p className="mb-3" style={{ fontSize: "calc(0.5em + 0.5vw)" }}>
              As a professional company we assist individuals or organizations
              in creating, designing, and maintaining software applications. It
              involves the entire software development lifecycle, from
              conceptualization and planning to coding, testing, deployment, and
              ongoing support. Some common services provided by our company are:
              Custom Software Development, Web Development, Mobile App
              Development, Software Consulting, Quality Assurance and Testing,
              Maintenance and Support, UI/UX Design etc.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SoftwareDevelopment;
