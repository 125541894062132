import { Container, Row, Image, Col } from "react-bootstrap";
import intentLogo from "../../img/data.jpg";

function BigDataSolutions() {
  return (
    <div
      style={{
        backgroundColor: "#edf0f2",
      }}
    >
      <Container
        style={{
          padding: "5% 0% 5% 0%",
        }}
      >
        <Row>
          <Col
            xs
            lg="5"
            className="d-flex justify-content-center align-items-center h-100 my-auto"
          >
            <Image
              src={intentLogo}
              width={500}
              height={350}
              alt="logo"
              style={{
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              }}
            />
          </Col>
          <Col xs lg="7">
            <h2 className="mb-3" style={{ fontSize: "calc(1.5em + 1.5vw)" }}>
              Big Data Solutions
            </h2>
            <p className="mb-3" style={{ fontSize: "calc(0.5em + 0.5vw)" }}>
              We help organizations effectively manage and leverage their vast
              amounts of data to gain actionable insights and drive business
              growth. We provide a wide range of services and solutions to
              address the challenges associated with capturing, storing,
              processing, and analyzing large and complex datasets. Some key
              activities are: Data Strategy and Consulting, Data Integration and
              Management, Big Data Infrastructure and Architecture, ata
              Analytics and Insights, Data Visualization and Reporting etc.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BigDataSolutions;
