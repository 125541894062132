import logo from "../../img/intent-1.png";
import { FaPhone, FaEnvelope } from "react-icons/fa";

function Contact(params) {
  return (
    <div class="ml-4" style={{textAlign:"center"}}>
      <img src={logo} height={80} alt="logo" />
      <div style={{ color: "white" }}>
        <p>
          {" "}
          <FaPhone /> +44 789 847 6951
        </p>
      </div>
      <div style={{ color: "white" }}>
        <p>
          <FaEnvelope />{" "}
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="mailto:hmetin@intentedu.com"
          >
            hmetin@intentedu.com
          </a>{" "}
        </p>
		Ground Floor, Unit 2, 493 Green Lanes<br/>London, England<br/>N13 4BS
      </div>
    </div>
  );
}
export default Contact;
