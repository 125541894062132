import { Col, Container, Row,Image } from "react-bootstrap";
import Form from "./form";
import SiteMap from "./sitemap";
import Contact from "./contact";

import iasbadge from "../../img/00120000013GWJMAA4_badge.png";

function Footer() {
  return (
    <>
      <div
        className="p-2 pt-5 mx-auto"
        style={{ backgroundColor: "#006298" }}
        id="footer"
      >
        <Container>
          <Row>
            <Col
              xs
              lg="4"
              className="d-flex align-items-top justify-content-center"
            >
              <SiteMap />
            </Col>{" "}
            <Col xs lg="4">
			<a href="https://www.icef.com/agency/00120000013GWJMAA4"  target="_blank">
			<Image src={iasbadge} alt="IAS Badge" width={120}/>
			</a> 
			</Col>{" "}
            <Col xs lg="4">
              <Contact />
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#0077b8", color: "white" }}>
        <Container>
          <p className="text-center m-0 p-2">
            &copy; 2023 by Intent. All rights reserved.
          </p>
        </Container>
      </div>
    </>
  );
}
export default Footer;
