function SiteMap(params) {
  return (
    <div style={{ color: "white", textAlign: "center" }}>
      <h2>Site map</h2>
      <p>All our pages</p>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li>
          <a href="/" style={{ color: "white", textDecoration: "none" }}>
            Home
          </a>
        </li>
        <li>
          <a href="/about" style={{ color: "white", textDecoration: "none" }}>
            About
          </a>
        </li>
        <li>
          <a
            href="/services"
            style={{ color: "white", textDecoration: "none" }}
          >
            Services
          </a>
        </li>
      </ul>
    </div>
  );
}
export default SiteMap;
