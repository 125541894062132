import Navbar from "./navbar";
import background from "../../img/intent_homepage.png";
import React from "react";
import video1 from "../../video/homepagevideo.mp4";
import HeaderAbout from "./headerabout";
function Header({ title }) {
  return (
    <header>
      <Navbar />
      <HeaderAbout />
    </header>
  );
}

export default Header;
