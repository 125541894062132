import { Container } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import intentLogo from "../../img/intentedu.png";
import ces from "../../img/ces-schools.png";
import kaplan from "../../img/kaplan-logo.png";
import twin from "../../img/twin_english_centres.png";
import fmss from "../../img/fmss.png";
import ec from "../../img/EC-english.jpg";
import zeta from "../../img/zeta.png";
import Image from "react-bootstrap/Image";


function Sponsors() {
  function renderSponsor(logo, name) {
    return (
      <Col xs lg="4" style={{padding:'30px 0px'}}>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginBottom: "25px" }}
        >
          <Image src={logo} alt="Enrolling" height={100} />
        </div>
        <div>
          <h4 className="d-flex align-items-center justify-content-center">
            {name}
          </h4>
          
        </div>
      </Col>
    );
  }
  return (
    <div
      style={{
        backgroundColor: "#edf0f2",
        padding: "60px 0",
        textAlign: "center",
      }}
      className="pt-5 pb-5"
    >
      <Container>
        <Row>
          <Col>
            <h2 style={{ marginBottom: "15px" }}>
              Trusted by industry-leading organizations worldwide
            </h2>
            
          </Col>
        </Row>
        <Row>
          
          {renderSponsor(ces, "Centre Of English Schools")}
          {renderSponsor(twin, "Twin English Centers")}
          {renderSponsor(fmss, "Future Mobile Software Solutions")}
          {renderSponsor(ec, "EC English Language Centres")}
          {renderSponsor(zeta, "Zeta Corporation")}  
        </Row>
      </Container>
    </div>
  );
}
export default Sponsors;
