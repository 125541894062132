import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import intentLogo from "../../img/intentedu.png";
import Image from "react-bootstrap/Image";
function SuccessStory() {
  function renderSponsor(description, name) {
    return (
      <Col xs lg="6" >
        <div>
          <p className="p-5 d-flex align-items-center justify-content-center">
            {description}
          </p>
          <h4 className="d-flex align-items-center justify-content-center" style={{ padding:" 0px 60px", fontFamily:"monospace"}}>
            {name}
          </h4>
        </div>
      </Col>
    );
  }
  return (
    <div
      style={{
        backgroundColor: "#edf0f2",
        padding: "60px 0",
        textAlign: "center",
      }}
      className="pt-5 pb-5"
    >
      <Container>
        <Row>
          <Col>
            <h2 style={{ marginBottom: "15px" }}>Success Story</h2>
          </Col>
        </Row>
        <Row>
          {renderSponsor(
            "Working with Mr. Metin is delightful. He has vast knowledge and experience in his business. He is quite professional and provides great insights and counseling. He has very strong interpersonal relationship and wide network. He was very helpful and managed all aspects of our project. We are looking forward to working with him again ",
            "Aylin Anarat "
          )}
          {renderSponsor(
            "I am delighted to share my experience with Intent, a truly exceptional partner in our journey towards project success. Working with Intent has been an absolute game-changer for our organization.",
            "Anonymous "
          )}
        </Row>
      </Container>
    </div>
  );
}
export default SuccessStory;
