import React, { useState, useEffect } from "react";

const ShowMore = ({ text, summary }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {showMore ? text : summary}
      <button onClick={() => setShowMore(!showMore)}>
        {showMore ? "Show less" : "Show more"}
      </button>
    </>
  );
};

const CustomResponsiveShowMore = ({ text, summary }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (width < 992) {
    return <ShowMore text={text} summary={summary} />;
  } else {
    return <>{text}</>;
  }
};

export default CustomResponsiveShowMore;
